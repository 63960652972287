import NetworkWorker from './network.worker'
import {getUserId, sessionId} from './UserService';
import {RT_ANALYTICS_API} from '../config';
import _ from 'lodash';
import abtest from './abtest';
import bridge from './bridge';
import DEBUG from './debug'
import {DateTime} from 'luxon';
import Utils from 'common/Utils';

class Story {

  options = {
    url: `${RT_ANALYTICS_API}/stories`
  };

  _shouldFireLoginEvent = false
  _worker
  _stepCounter = 0

  init() {
    Utils.isGDPR(_.result(bridge, 'session.country', 'unknown'));

    this._worker = new NetworkWorker()
    this._worker.postMessage({_options: this.options})
    if (localStorage) {
      const key = localStorage.getItem('rt-story-key');
      if (key === sessionId) {
        this._stepCounter = _.toNumber(localStorage.getItem('rt-story-next-step'));
        if (_.isNaN(this._stepCounter)) this._stepCounter = 0;
      } else {
        localStorage.setItem('rt-story-key', sessionId);
        localStorage.setItem('rt-story-next-step', null);
        this._shouldFireLoginEvent = true;
      }

    }
  }

  shouldFireLoginEvent() {
    return this._shouldFireLoginEvent;
  }

  getStep() {
    return this._stepCounter;
  }

  pricingTableOpened(source) {
    if (_.isUndefined(source) || _.isNil(source)) return;
    if (window.localStorage) {
      localStorage.setItem('rt_sub_campaign', source)
    }
    this.addStep('pricing_table', {
      param1: 'open',
      param2: source,
    })
  }

  mapIfHasValue(object, objectPropNameForValue, value) {
    if (!_.isUndefined(value) && !_.isNil(value)) {
      object[objectPropNameForValue] = value
    }
  }

  genericEvent(action, category, label, value) {
    if (_.isUndefined(action) || _.isNil(action)) return;
    this.addStep('ga__'+action, {
      param1: category,
      param2: label,
      param3: value,
    })
  }

  classCreated(classId, grade, school) {
    this.addStep('create_class', {
      param1: classId,
      param2: grade,
      param3: school,
    })
  }

  validationError(inputVal, errorMessageType, errorMessage) {
    this.addStep('validation_error', {
      param1: inputVal,
      param2: errorMessageType,
      param3: errorMessage,
    })
  }

  studentCreated(studentId, classId) {
    this.addStep('create_student', {
      param1: studentId,
      param2: classId ? classId : 0,
    })
  }

  studentRemoved(studentId) {
    this.addStep('untrack_student', {
      param1: studentId,
    })
  }

  reportSchoolName(schoolName) {
    this.addStep('teacher_school', {
      param1: schoolName,
    })
  }

  reportJobTitle(jobTitle) {
    this.addStep('teacher_job', {
      param1: jobTitle,
    })
  }

  studentReportDateRangeSelected(start, total, studentId) {
    this.addStep('student_report_date_range', {
      param1: start,
      param2: total,
      param3: studentId,
    })
  }

  studentReportPrinted(start, total, studentId) {
    this.addStep('student_report_printed', {
      param1: start,
      param2: total,
      param3: studentId,
    })
  }

  classReportDateRangeSelected(start, total, classId) {
    this.addStep('class_report_date_range', {
        param1: start,
        param2: total,
        param3: classId,
    })
  }

  classReportPrinted(start, total, classId) {
    this.addStep('class_report_printed', {
        param1: start,
        param2: total,
        param3: classId,
    })
  }

  studentPdfReportExport(start, total, studentId) {
    this.addStep('student_pdf_report_export', {
      param1: start,
      param2: total,
      param3: studentId,
    })
  }

  classPdfReportExport(start, total, classId) {
    this.addStep('class_pdf_report_export', {
      param1: start,
      param2: total,
      param3: classId,
    })
  }

  getAndIncStep() {
    const curr = this._stepCounter;
    this._stepCounter++;
    localStorage.setItem('rt-story-next-step', ''+this._stepCounter);
    return curr;
  }

  addStep(event_type, extra_fields = {}) {
    const step = {
      created_at: DateTime.local().setZone('America/New_York').toISO({ includeOffset: false }),
      user_id: ''+getUserId(),
      session_id: sessionId,
      step: this.getAndIncStep(),
      ab_test: abtest.abTestId,
      ab_test_uid: abtest.uid,
      client_ver: $$VERSION$$,
      server_ver: bridge.version,
      event_type: event_type,
      href: window.location.href,
      country : _.result(bridge, 'session.country', 'unknown')
    };
    for (let i=1; i<6; i++) {
      if (extra_fields[`param${i}`]) {
        step[`param${i}`] = extra_fields[`param${i}`];
      }
    }

    this._worker.postMessage(step)
    if (DEBUG){
      console.group('Story');
      console.table(step);
      console.groupEnd();
    }

  }
}
export default new Story()
